import './homescreen.css';
import Header from "../header";
import Typed from 'react-typed';
import staticTV from "./assets/tv_static.mp4"

import sample from "./assets/dance_party.mp4"
import sample2 from "./assets/tiffany_co.mp4"
import sample3 from "./assets/tiffany_opening.mp4"
import sample4 from "./assets/tiffany_cheeks.mp4"

import VideoPlayer from "react-background-video-player";

import sound from "./assets/Cheek to Cheek.mp3"
import sound2 from "./assets/In the Mood.mp3"
import sound3 from "./assets/La Vie En Rose.mp3"
import sound4 from "./assets/Girl from Ipanema.mp3"

import staticNoise from "./assets/radiostatic.mp3"
import { useState } from 'react';


const audio = new Audio(sound);
const audio2 = new Audio(sound2);
const audio3 = new Audio(sound3);
const audio4 = new Audio(sound4);

const noise = new Audio(staticNoise);


var totalArray = [];
export const datas = [
  { name: 'Cheek to Cheek', audio: audio, background: sample4 },
  { name: 'In the Mood', audio: audio2, background: sample },
  { name: 'La Vie En Rose', audio: audio3, background: sample3 },
  { name: 'Girl from Ipanema', audio: audio4, background: sample2 }
]



let currentIndex = 0;

function HomePage() {

  //Contians imagesd and background

  totalArray.push({ name: 'Cheek to Cheek', audio: audio, background: sample4 });
  totalArray.push({ name: 'In the Mood', audio: audio2, background: sample});
  totalArray.push({ name: 'La Vie En Rose', audio: audio3, background: sample3 });
  totalArray.push({ name: 'Girl from Ipanema', audio: audio4, background: sample2 });





  //Changing background function (Make it into array list of string)


  const [currVideo, setVideo] = useState(staticTV);



  const backgroundChange = (param1) => {
    currentIndex += param1

    if (currentIndex == totalArray.length) currentIndex = 0;
    else if (currentIndex == -1) currentIndex = totalArray.length - 1;
   
    
    setVideo(totalArray.at(currentIndex).background);


    
    for (const child of document.getElementById("bg").children) {
      if (child.className === "subtextSpace") {
        for (const child2 of child.children) {
          if (child2.className === "fontSubtext")   child2.innerHTML = totalArray.at(currentIndex).name;
        }
        break;
      }
    }
    for (let i = 0; i < totalArray.length; i++) {
      totalArray.at(i).audio.pause();
    }
    totalArray.at(currentIndex).audio.loop = true;
    totalArray.at(currentIndex).audio.volume = 0.1;
    totalArray.at(currentIndex).audio.play();
  }
  return (
    
    <div id="bg" className="Home">
      <VideoPlayer className="video" id = "myVideo"
        src={currVideo}
        autoPlay={true}
        muted={true}
        display = {false}
      />  





      <Header/>




      <div className="subtextSpace">
        <Typed strings={["Hello World!",]}
          className="fontSubtext"
          typeSpeed={200}
          showCursor = {false}
        />

        <div className="subButtonSpace">
            <button className="previousTrack" onClick={() => backgroundChange(-1)} > Previous </button>
            <button className="nextTrack" onClick={() => backgroundChange(1)}> Next </button>
        </div>
      </div>

     
    </div>
  );
}





export default HomePage;