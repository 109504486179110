import './aboutme.css';
import { useNavigate } from 'react-router-dom';
import sample from "./assets/TKM.mp4"
import { useEffect, useState } from 'react';

import background4 from './assets/computer_screen.gif'
import resume from './assets/Ryan Oh_resume.pdf'

import VideoPlayer from "react-background-video-player";



const AboutMe = () => {
  const navigate = useNavigate();
  const handleClick = () => navigate('/');
  const [mutedVideo, setMutedVideo] = useState(true);

  
 

  
  return (
    <div className="AboutMe">
      <VideoPlayer className="video2" id = "myVideo" 
        src={sample}
        autoPlay={true}
        muted={mutedVideo}
      />

      <h2 className="Title" onClick={() => setMutedVideo(false)}>
        About me
      </h2>

      
      
      
      <div className = "TextField">
        <h2 classname = "Introduction">
          Ryan Oh, <br/> a sophomore at UCLA pursuing B.S in Computer Science. 
          <br/> <br/>
          I am a full-stack engineer, proficient in React, C++, Java, C, Python,  
          <br/> and etc. <br/>
  
        </h2>
        
        <div classname = "button_space">
          <a className = "resume_button" href = {resume} download> Resume </a>
          <a className = "instagram_button" href = 'https://instagram.com/ryanoh279?igshid=MmIzYWVlNDQ5Yg==' target="_blank"> Instagram</a>
        </div>
       
      </div>





      

      <button className="return2" onClick={handleClick}> Back </button>
    </div>

  );
}
export default AboutMe